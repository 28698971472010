html,
body {
	height: 100%;
	font-family: 'Roboto', sans-serif;
	box-sizing: border-box;
	line-height: 1.75;
	letter-spacing: 0;
	color: rgba(0, 0, 0, 0.65);
}

#root {
	width: 100%;
	height: 100%;
}

br + br {
	display: none;
}

ul {
	margin: 0;
	padding: 0;
	list-style: none;
}

img {
	position: relative;
	display: block;
	width: 100%;
	height: auto;
	font-weight: 300;
	text-align: center;
	line-height: 2;
}

.haveFiber {
	display: flex;
	align-items: center;
}

.fiber {
	margin-left: 2.5px;
	color: #fff500;
}

.material-icons,
.material-icons-outlined {
	font-size: 18px;
}
